.profileSec {
  .profileNameDetail {
    position: relative;

    .profileImg {
      img,
      canvas {
        height: 118px !important;
        width: 118px !important;
        border-radius: 59px;
        border: 2px solid var(--darkPurple);
      }
    }

    .editProfile {
      position: absolute;
      top: 0;
      right: 0;

      @media (max-width: 424px) {
        position: relative;
        margin-top: 15px;
      }
    }
  }

  .nameSec {
    h2 {
      color: var(--white);
      font-size: var(--headingFive);
      font-weight: 600;
    }

    h3 {
      color: var(--darkPurple);
      font-size: var(--largeParaGraph);
      font-weight: 500;
      padding-top: 5px;
    }

    p {
      color: var(--grayDarkABB9C4);
      font-size: var(--largeParaGraph);
      font-weight: 500;
      padding-top: 13px;
      word-break: break-all;

      span {
        &:first-child {
          @media (max-width: 767px) {
            width: 243px;
            overflow: hidden;
            text-overflow: ellipsis;
            display: inline-block;
            white-space: nowrap;
          }
        }
      }

      svg {
        cursor: pointer;
        margin-left: 10px;
      }
    }
  }

  .cardOuter {
    display: flex;
    gap: 30px;
    margin-top: 10px;
    justify-content: flex-start;
    position: relative;

    &::after {
      position: absolute;
      content: "";
      background-image: linear-gradient(
        90deg,
        rgba(1, 11, 26, 0) 20%,
        rgba(255, 255, 255, 0.2),
        rgba(1, 11, 26, 0) 80%
      );
      width: 100%;
      height: 1px;
      bottom: 0;
    }

    @media (max-width: 767px) {
      flex-wrap: wrap;
    }
  }

  .cardOne {
    border-radius: 20px;
    // background: var(--black031B30);
    padding: 25px;
    max-width: 250px;
    width: 100%;

    @media (max-width: 575px) {
      max-width: 45%;
      width: 100%;
    }

    h2 {
      color: var(--grayDarkABB9C4);
      font-size: var(--largeParaGraph16);
      font-weight: 500;
    }

    h3 {
      color: var(--white);
      font-size: var(--headingTwo);
      font-weight: 600;
      padding-top: 5px;
    }
  }

  .profileTab {
    margin-top: 50px;
    margin-bottom: 70px;
  }
}

.tabSelect {
  height: 50px;
  position: relative;
  min-width: 130px;
  width: 200px;
  margin-left: 6px;
  margin-bottom: 15px;

  &.marketplace {
    width: 215px;
    // @media (max-width: 421px) {
    //   width: 100%;
    // }
  }

  &::before {
    position: absolute;
    background-image: url("../../assets/Export.svg");
    content: "";
    width: 17px;
    height: 17px;
    z-index: 1;
    background-repeat: no-repeat;
    top: 18px;
    left: 15px;
    filter: brightness(0) saturate(100%) invert(100%) sepia(100%) saturate(0%)
      hue-rotate(12deg) brightness(105%) contrast(103%);
  }

  .ant-select-selector {
    border-radius: 30px;
    border: 1px solid var(--inputColor) !important;
    background: var(--inputColor) !important;

    &:hover {
      border: 1px solid var(--darkPurple) !important;
    }

    .ant-select-selection-item {
      color: var(--white);
      font-size: var(--largeParaGraph16);
      font-weight: 500;
      text-transform: capitalize;
      font-family: Montserrat;
      padding: 0;
      text-align: center;
    }

    .ant-select-selection-search-input {
      color: var(--white) !important;
      padding-left: 24px !important;
      padding-right: 15px !important;
    }
  }

  .ant-select-arrow {
    .anticon {
      svg {
        path {
          fill: var(--white);
        }
      }
    }
  }
}

.ant-select-dropdown {
  border-radius: 10px;
  background: var(--inputColor);

  .ant-select-item {
    color: var(--white);
    font-family: Montserrat;
    font-size: var(--regularParagraph);
    font-weight: 500;
  }

  .ant-select-item-option-active,
  .ant-select-item-option-selected {
    background-color: transparent !important;
    color: var(--darkPurple) !important;
  }
}

.positionSec {
  .commonTable {
    .fix_width {
      white-space: nowrap !important;
      width: 100% !important;
    }

    .marketTd {
      display: flex;
      align-items: center;
      gap: 10px;

      svg {
        width: 52.718px;
        height: 52.736px;
      }
    }
  }
}

.sureModel {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;

  .innerSec {
    padding-top: 25px;

    h2 {
      color: var(--white);
      font-size: var(--headingFive);
      font-weight: 600;
      padding-top: 5px;
    }

    p {
      color: var(--whited8d8d8);
      font-size: var(--regularParagraph);
      font-weight: 500;
      padding-top: 12px;
    }
  }

  .btn {
    display: flex;
    gap: 20px;
    width: 100%;
    margin-top: 40px;
  }
}

.editModal {
  .heading {
    color: var(--white);
    font-size: var(--headingFive);
    font-weight: 600;
    padding-top: 5px;
    text-align: left;
  }

  .inputLayout .input_custom {
    background: #072139;
  }

  .upload {
    display: flex;
    gap: 18px;
    align-items: center;
    margin-top: 29px;
    margin-bottom: 20px;
    position: relative;

    img,
    canvas {
      height: 118px !important;
      width: 118px !important;
      border-radius: 59px;
      margin: auto;
    }

    .uploadBtn {
      border-radius: 20px;
      background: #28b0de;
      border: 0;
      color: var(--white);
      font-size: var(--mediumParaGraph);
      font-weight: 500;
      height: 40px;
      width: 40px;
      padding: 10px 18px;
      border: 2px solid var(--modalBg);
      .ant-btn-icon {
        svg {
          display: table;
        }
      }

      &:hover {
        background: #28b0de;
        color: var(--white);
      }
    }

    .error {
      width: 70%;
    }

    .ant-upload-wrapper {
      position: absolute;
      right: 28%;
      top: 52%;
    }
  }

  .btn {
    margin-top: 40px;
  }
}
