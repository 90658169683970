.errorBoundary {
  text-align: center;
  padding: 15px;
  background-color: var(--Mainblack);
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  img {
    max-width: 121px;
    width: 100%;
    height: 50px;
  }
  &_content {
    max-width: 604px;
    width: 100%;
    padding: 80px 40px;
    border-radius: 8px;
    background-color: #0000005c;
    box-shadow: 0px 0px 22px -8px rgba(255, 255, 255, 0.13);

    .back-to-home {
      display: flex;
      align-items: center;
      margin: 30px 164px 0px;
    }
    @media (max-width: 767px) {
      padding: 40px 15px;
    }
    h4 {
      font-size: var(--headingOne);
      color: var(--white);
      font-weight: 600;
      margin-bottom: 16px;
      margin-top: 20px;
    }

    h6 {
      font-size: var(--largeParaGraph);
      color: var(--white);
      font-weight: 400;
      margin-bottom: 24px;
    }
    p {
      font-size: var(--mediumParaGraph);
      color: var(--white);
      font-weight: 400;
      font-style: italic;
      margin-bottom: 32px;
    }
  }
}
