@import url("https://fonts.googleapis.com/css2?family=Archivo:wght@100;200;300;400;500;600;700;800;900&family=Montserrat:ital,wght@0,100..900;1,100..900&family=Nunito:wght@200;300;400;500;600;700;800;900&family=Open+Sans:wght@300;400;500;600;700;800&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Raleway:ital,wght@0,100..900;1,100..900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Sofia+Sans:ital,wght@0,1..1000;1,1..1000&display=swap");
:root {
  margin: 0;
  // font-family: "Montserrat", sans-serif !important;
  font-synthesis: none;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  --darkPurple: #28b0de;
  --brandPurple: #5700ff;
  --brandPurple10: #5700ff10;
  --coreGray: #e0e0e0;
  --headerBg: #010516;
  --bgcliam: #1e3040;
  --offWhite: #f9f9fd;
  --black: #000000;
  --mutedBlack: #1a2733;
  --white: #ffffff;
  --validation: #ffb82d;
  --error: #ff3932;
  --success: #2ccc78;
  --disabledButton: #5500ff10;
  --blue: #4285f4;
  --blueBannerText: #00cbdf;
  --lightPink: #f8f8fc;
  --black60: #00000099;
  --gray2e5a6d: #2e5a6d;
  --grayDBDBDB: #dbdbdb;
  --grayDarkABB9C4: #abb9c4;
  --gray223648: #223648;
  --layoutbgcolor: #152029;
  --blue253b4e: #253b4e;
  --whited8d8d8: #d8d8d8;
  --black031B30: #031b30;
  // ==================================
  --bgBanner: linear-gradient(
    90.78deg,
    #01162a 43.25%,
    rgba(1, 22, 42, 0) 100.65%
  );
  --lightWhite: #b3bdcf;
  --btnBorder: #2e5a6d;
  --bgMain: #010516;
  --gradientBorder: linear-gradient(
    90deg,
    rgba(12, 194, 255, 0.5) -14.71%,
    rgba(1, 11, 26, 0) 115.47%
  );
  --cardBg: #01172a;
  --modalBg: #010516;
  --inputColor: #072139;

  --heading58: 58px;
  --heading52: 52px;
  --heading48: 48px;
  --heading40: 40px;
  --heading30: 30px;
  --headingOne: 32px;
  --heading28: 28px;
  --headingTwo: 22px;
  --headingThree: 24px;
  --headingFour: 20px;
  --headingFive: 26px;
  --largeParaGraph: 18px;
  --largeParaGraph16: 16px;
  --regularParagraph: 15px;
  --mediumParaGraph: 14px;
  --smallParagraph: 13px;
}

@media screen and (max-width: 991px) {
  :root {
    --heading58: 38px;
    --heading52: 40px;
    --heading48: 38px;
    --heading30: 26px;
    --heading40: 38px;
    --headingOne: 28px;
    --heading28: 22px;
    --headingTwo: 20px;
    --headingThree: 22px;
    --headingFour: 18px;
    --headingFive: 24px;
    --largeParaGraph: 16px;
    --largeParaGraph16: 15px;
    --regularParagraph: 14px;
    --mediumParaGraph: 13px;
    --smallParagraph: 12px;
  }
}

@media screen and (max-width: 768px) {
  :root {
    --heading58: 28px;
    --heading52: 30px;
    --heading48: 28px;
    --heading30: 22px;
    --heading40: 30px;
    --headingOne: 24px;
    --heading28: 18px;
    --headingTwo: 18px;
    --headingThree: 20px;
    --headingFour: 16px;
    --headingFive: 21px;
    --largeParaGraph: 14px;
    --largeParaGraph16: 13px;
    --regularParagraph: 13px;
    --mediumParaGraph: 12px;
    --smallParagraph: 11px;
  }
}

@media screen and (max-width: 480px) {
  :root {
    --heading58: 18px;
    --heading52: 26px;
    --heading48: 24px;
    --heading30: 18px;
    --heading40: 26px;
    --headingOne: 22px;
    --heading28: 16px;
    --headingTwo: 16px;
    --headingThree: 18px;
    --headingFour: 14px;
    --headingFive: 18px;
    --largeParaGraph: 14px;
    --largeParaGraph16: 14px;
    --regularParagraph: 12px;
    --mediumParaGraph: 12px;
    --smallParagraph: 11px;
  }
}

html,
body {
  scroll-behavior: smooth;
}

body {
  background-color: var(--bgMain);
}

.container {
  max-width: 1640px;
  margin: 0 auto;
  // padding: 0 20px;
}

* {
  margin: 0;
  // font-family: "Montserrat", sans-serif !important;
  // font-family: "Raleway", sans-serif !important;
  font-family: "Sofia Sans", serif !important;
  line-height: normal;
  box-sizing: border-box;
}

::-webkit-scrollbar {
  width: 5px;
}

::-webkit-scrollbar-thumb {
  background-color: #252727f3;
  border-radius: 2px;
}

::-webkit-scrollbar-track {
  background-color: #0d1a24;
}

.ant-drawer {
  .ant-drawer-content {
    background: var(--headerBg);
    box-shadow: 0px 4px 36px 0px rgba(220, 255, 250, 0.08);
  }

  .ant-drawer-header {
    border-color: #273a4d;

    .anticon {
      svg {
        path {
          fill: var(--white);
        }
      }
    }
  }
}

.commonTab {
  .ant-tabs-tab {
    padding: 0;
    margin: 0 !important;
  }

  .ant-tabs-nav-list {
    margin-bottom: 15px;
  }

  .ant-tabs-tab-btn {
    padding: 16px 23px;
    color: var(--grayDarkABB9C4);
    font-size: var(--largeParaGraph16);
    font-family: Montserrat;
    font-weight: 600;
    background-color: #072139;
    border-radius: 30px;
    margin: 0 6px;

    &:active,
    &:focus {
      color: var(--darkPurple) !important;
    }
  }

  .ant-tabs-tab.ant-tabs-tab-active {
    .ant-tabs-tab-btn {
      border-radius: 25px;
      background: var(--darkPurple);
      color: var(--headerBg);

      &:active,
      &:focus {
        color: var(--headerBg) !important;
      }
    }
  }

  .ant-tabs-ink-bar {
    display: none;
  }

  .ant-tabs-nav::before {
    border-bottom: unset;
    opacity: 0.4;
  }

  .ant-tabs-nav-operations {
    .anticon {
      svg {
        path {
          fill: var(--darkPurple);
        }
      }
    }
  }
}

.ant-table-wrapper.commonTable .ant-table-tbody > tr > td {
  border-bottom: unset;
}

.ant-table-wrapper.commonTable .ant-table-tbody > tr {
  position: relative;
  padding: 10px;

  &::after {
    content: "";
    position: absolute;
    top: -2px;
    left: -2px;
    right: -2px;
    bottom: -2px;
    background: linear-gradient(
      90deg,
      rgba(1, 11, 26, 0) -14.71%,
      rgba(12, 194, 255, 0.2) 53.33%,
      rgba(1, 11, 26, 0) 115.47%
    );
    border-radius: 4px;
    height: 1px;
  }
}

.commonTable {
  .ant-table {
    border-radius: 20px;
    border: 1px solid rgba(7, 33, 57, 0.8);
    background: transparent;

    .ant-table-content {
      overflow: auto;
      border-radius: 20px;

      table {
        overflow: auto;
      }
    }

    th {
      background: var(--black031B30) !important;
      color: var(--white) !important;
      font-family: Montserrat;
      font-size: var(--largeParaGraph16);
      font-weight: 500 !important;
      text-transform: capitalize;
      border: 0 !important;
      white-space: nowrap;

      &::before {
        display: none;
      }
    }

    tr:hover > td {
      background-color: #2e5a6d69 !important;
    }

    td {
      // border-color: #386a7f !important;
      font-family: Montserrat;
      font-size: var(--largeParaGraph16);
      font-weight: 500 !important;
      text-transform: capitalize;
      color: var(--grayDarkABB9C4);
      white-space: nowrap;

      &:first-child {
        color: var(--white) !important;
      }
    }

    tr {
      &:last-child {
        td {
          border: 0 !important;
        }
      }
    }

    .yes {
      color: var(--success);
    }

    .no {
      color: var(--error);
    }

    .action {
      color: var(--darkPurple);
      text-decoration-line: underline;
    }

    .ant-table-container {
      border-start-start-radius: 20px;
      border-start-end-radius: 20px;
    }

    .ant-table-container table > thead > tr:first-child > :first-child {
      border-start-start-radius: 20px;
    }

    .ant-table-container table > thead > tr:first-child > :last-child {
      border-start-end-radius: 20px;
    }
  }

  .ant-table-placeholder {
    .ant-table-cell {
    }
    &:hover {
      background-color: var(--headerBg) !important;
    }
  }

  .ant-table-cell {
    background: transparent !important;

    .ant-empty-description {
      color: var(--white);
    }
  }

  .ant-pagination {
    justify-content: center;

    @media (max-width: 575px) {
      scale: 0.9;
    }
  }

  .ant-pagination-item {
    display: flex;
    justify-content: center;
    align-items: center;

    a {
      color: var(--lightWhite);
    }
  }

  .ant-pagination .ant-pagination-item-active {
    font-weight: 600;
    background-color: transparent;
    border-radius: 2px;
    border: none;
    display: flex;
    justify-content: center;
    align-items: center;

    a {
      color: var(--white);
    }
  }

  .ant-spin {
    max-height: 100% !important;
  }

  .ant-pagination-prev,
  .ant-pagination-next {
    background-color: var(--darkPurple);

    &.ant-pagination-disabled {
      background-color: var(--inputColor);

      .anticon {
        svg {
          path {
            fill: var(--lightWhite);
          }
        }
      }
    }

    .anticon {
      svg {
        path {
          fill: var(--white);
        }
      }
    }
  }

  .ant-pagination .ant-pagination-disabled {
    opacity: 0.6;
  }
  :where(
      .css-dev-only-do-not-override-1hpnbz2
    ).ant-select-single.ant-select-sm {
    height: 32px !important;
  }
}

.ant-table-wrapper .ant-table-tbody > tr.ant-table-placeholder {
  background: transparent;
}

.commonModal {
  .ant-modal-content {
    border-radius: 30px;
    background: var(--modalBg);
    border: 1px solid rgba(12, 194, 255, 0.2);

    .ant-modal-header {
      background-color: transparent;
      margin: 0;
    }

    .ant-modal-title {
      color: var(--white);
      font-family: Montserrat;
      font-size: var(--headingFive);
      font-weight: 600;
    }

    .ant-modal-close {
      .anticon {
        svg {
          path {
            fill: #d1d0d0;
          }
        }
      }
    }
  }
}

.ant-select-focused .ant-select-selector {
  border-color: var(--darkPurple) !important;
}

.cmnSelect {
  display: flex;
  flex-direction: column;
  align-items: baseline;
  position: relative;
  gap: 10px;

  label {
    color: var(--whited8d8d8);
    font-family: Montserrat;
    font-size: var(--largeParaGraph16);
    font-weight: 500;
  }

  .ant-select {
    width: 100%;
    height: 50px;

    &.ant-select-open .ant-select-focused {
      border-color: var(--darkPurple) !important;
    }

    &.ant-select-outlined:not(.ant-select-disabled):not(
        .ant-select-customize-input
      ):not(.ant-pagination-size-changer):hover
      .ant-select-selector {
      border-color: var(--darkPurple) !important;
    }

    .ant-select-selection-placeholder {
      font-size: var(--largeParaGraph16);
    }

    .ant-select-selector {
      border-radius: 10px;
      background: var(--inputColor);
      backdrop-filter: blur(7px);
      border: 1px solid transparent;
      border-radius: 30px;

      .ant-select-selection-item {
        color: var(--white);
        font-family: Montserrat;
        font-size: var(--largeParaGraph16);
        font-weight: 500;
        text-align: left;
      }
    }

    .ant-select-arrow {
      svg {
        path {
          fill: var(--white);
        }
      }
    }
  }

  .error {
    position: absolute;
    bottom: -23px;
  }
}

.ant-select-arrow {
  .anticon {
    svg {
      path {
        fill: var(--white);
      }
    }
  }
}

.ant-empty-description {
  color: var(--white) !important;
}

.cmnDatePicker {
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 10px;
  text-align: left;

  @media (max-width: 767px) {
    margin-top: 30px;
  }

  .error {
    position: absolute;
    bottom: -23px;
  }

  label {
    color: var(--whited8d8d8);
    font-family: Montserrat;
    font-size: var(--largeParaGraph16);
    font-weight: 500;
  }

  .ant-picker {
    position: relative;
    border-radius: 30px;
    background: var(--inputColor);
    backdrop-filter: blur(7px);
    border: 1px solid transparent;
    height: 50px;
    width: 100%;

    &.ant-picker-outlined:hover {
      border-color: var(--darkPurple);
    }

    input {
      color: var(--white);
      font-family: Montserrat;
      font-size: var(--largeParaGraph16);
      font-weight: 500;

      &::placeholder {
        color: var(--grayDarkABB9C4);
      }
    }

    .ant-picker-suffix,
    .ant-picker-clear {
      svg {
        path {
          fill: var(--white);
        }
      }
    }
  }
}

.ant-picker-cell-disabled {
  opacity: 0.6;
  cursor: not-allowed !important;
  pointer-events: unset !important;

  .ant-picker-cell-inner {
    color: #dddddd52;
  }
}

.ant-picker-time-panel {
  .ant-picker-content {
    .ant-picker-time-panel-cell-disabled {
      .ant-picker-time-panel-cell-inner {
        color: #dddddd52 !important;
      }
    }
  }
}

.ant-collapse {
  border: unset;

  .ant-collapse-item {
    transition: linear 0.5s ease-in-out;
    cursor: pointer;
    background: linear-gradient(
      272.15deg,
      rgba(23, 37, 50, 0.08) -2.55%,
      rgba(37, 59, 79, 0.685538) 50.31%,
      #131d26 103%
    );
    border: 1px solid #ffffff1a;
    margin-bottom: 20px;
    border-radius: 10px;
    text-align: left;

    .ant-collapse-header {
      flex-direction: row-reverse;
      color: var(--white);
      font-size: var(--largeParaGraph);
      font-weight: 600;
      padding: 30.5px 22px;
    }

    .ant-collapse-content {
      border-radius: 0 0 10px 10px;
      background: transparent;
      border: unset;
      font-size: var(--largeParaGraph16);
      font-weight: 500;
      color: var(--white);
    }

   
  }

  .ant-collapse-item-active {
    background: linear-gradient(89.02deg, #3e87b0 1.19%, #a4c0cf 99.34%);
    .ant-collapse-header,
    .ant-collapse-content {
      color: #111b24;
    }
  }
}

.ant-picker-ok {
  button {
    color: var(--white) !important;
  }
}

:where(.css-dev-only-do-not-override-1kuana8).ant-collapse
  > .ant-collapse-item:last-child,
:where(.css-dev-only-do-not-override-1kuana8).ant-collapse
  > .ant-collapse-item:last-child
  > .ant-collapse-header {
  border-radius: 10px;
  margin-bottom: 0;
}

.commonTextArea {
  display: flex;
  flex-direction: column;
  gap: 10px;
  text-align: left;
  width: 100%;
  position: relative;

  label {
    color: var(--whited8d8d8);
    font-family: Montserrat;
    font-size: var(--largeParaGraph16);
    font-weight: 500;
  }

  .ant-input {
    border-radius: 10px;
    background: #1e3040;
    backdrop-filter: blur(7px);
    border: 0;
    color: var(--white);
    font-family: Montserrat;
    font-size: var(--largeParaGraph16);
    font-weight: 500;
    text-align: left;

    &::placeholder {
      color: var(--grayDarkABB9C4);
    }
  }

  .error {
    position: absolute;
    bottom: -23px;
  }
}
.blueText {
  color: var(--blueBannerText);
}
.green,
.underprocess {
  color: var(--success);
}

.red {
  color: var(--error) !important;
}

.grey {
  color: var(--darkPurple) !important;
}

.yellow {
  color: #f3ba2f;
}

.orange,
.raised {
  color: #f3732f;
}

.ant-select-selection-placeholder {
  font-size: 14px;
  color: #abb9c4 !important;
  text-align: left;
}

.error {
  color: #ff2a00;
  font-size: 0.8rem;
  font-weight: 500;
  text-align: start;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  -moz-appearance: textfield;
}
.profileAddress:hover {
  p {
    color: #fff !important;
  }
  svg {
    path {
      fill: #fff !important;
    }
  }
}
.profileAddress {
  display: flex;
  gap: 9px;
  align-items: center;
  height: 58px;
  width: 100%;
  &:first-child {
    padding-left: 10px;
  }
  img,
  canvas {
    max-width: 100%;
    width: 38px;
    height: 38px;
    border-radius: 19px;
  }

  .address {
    h1 {
      color: var(--white);
      font-family: Montserrat;
      font-size: 15px;
    }

    p {
      color: var(--grayDarkABB9C4);
      font-size: 13px;
      padding-top: 2px;
      display: flex;
      align-items: center;
      gap: 15px;
    }
  }
}

.ant-spin-dot-item {
  background-color: #ffffff !important;
  /* Change the spinner dot color */
}

.fix_width {
  // width: 500px;
  // white-space: wrap;
  word-wrap: break-word;
  gap: 10px;
  display: flex;
  align-items: center;
  // img {
  //   width: 39px;
  //   height: 39px;
  //   border-radius: 50%;
  // }
  img {
    border-radius: 50%;
  }
}

.coin-icons {
  width: 20px;
  height: 20px;
  border-radius: 19px;
  cursor: default;
}

.coin-icons.marginright {
  margin-right: 10px;
}

.no-events-found {
  width: 100%;
  color: white;
  height: 250px;
  font-size: 15px;
  display: flex;
  border-radius: 20px;
  align-items: center;
  justify-content: center;
  border: 1px solid rgba(12, 194, 255, 0.2);
  min-width: 320px;

  @media (max-width: 479px) {
    min-width: 260px;
  }
}

.betNoeventFound {
  height: unset;
  padding: 20px 0px;
}

.wrapperSpin {
  .ant-spin {
    max-height: 100% !important;
    background: #070707e8;

    .ant-spin-dot {
      position: sticky !important;
    }
  }
}

.ant-spin-text {
  color: #999;
}

.load-more {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  gap: 20px;
  margin-top: 20px;

  &-div {
    display: flex;
    // padding: 20px;
    justify-content: center;

    // width: 100%;
    &.add-height {
      height: 150px;
    }

    button {
      width: 140px !important;
    }
  }

  .topbutton {
    position: absolute;
    right: 0;
    bottom: -20%;
    max-width: fit-content;
  }
}

.downArrow {
  svg {
    rotate: 180deg;
  }
}

.loader-container {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #7373738a;
  /* Change this to your desired background color */
  backdrop-filter: blur(8px);
}

.loading {
  position: fixed;
  z-index: 999;
  height: 2em;
  width: 2em;
  overflow: show;
  margin: auto;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}

.ant-picker-panel-layout {
  background: var(--inputColor);

  .ant-picker-header {
    button {
      color: #fff;
    }

    .ant-picker-header-view {
      color: #fff;
    }
  }

  .ant-picker-body {
    th,
    td {
      color: #fff;
    }
  }

  .ant-picker-content {
    .ant-picker-time-panel-cell-inner {
      color: #fff !important;
    }

    .ant-picker-time-panel-cell-selected {
      .ant-picker-time-panel-cell-inner {
        background-color: var(--darkPurple) !important;
        border: 1px solid var(--darkPurple) !important;
      }
    }
  }

  .ant-picker-now {
    a {
      color: var(--darkPurple) !important;
    }
  }
}

.ant-picker-dropdown
  .ant-picker-cell-in-view.ant-picker-cell-selected:not(
    .ant-picker-cell-disabled
  )
  .ant-picker-cell-inner,
.ant-picker-dropdown
  .ant-picker-cell-in-view.ant-picker-cell-range-start:not(
    .ant-picker-cell-disabled
  )
  .ant-picker-cell-inner,
.ant-picker-dropdown
  .ant-picker-cell-in-view.ant-picker-cell-range-end:not(
    .ant-picker-cell-disabled
  )
  .ant-picker-cell-inner {
  background: var(--darkPurple) !important;
}

.ant-btn-variant-solid {
  background: var(--darkPurple) !important;
}

.ant-picker-panel-container {
  background: var(--darkPurple) !important;
}

.ant-dropdown-menu {
  background: var(--headerBg);
}

.customize_overlay {
  .ant-dropdown-menu {
    background: var(--inputColor);
  }

  .ant-menu-title-content {
    a {
      color: #fff;

      &:hover {
        color: #fff;
      }
    }
  }

  .ant-menu-item-selected:first-child {
    background-color: transparent !important;
  }

  .ant-menu-item-selected:not(:first-child) {
    background-color: var(--darkPurple) !important;

    .ant-menu-title-content {
      a {
        color: #000;
      }
    }
  }
}

.wallet_overlay {
  .ant-dropdown-menu {
    background-color: var(--inputColor) !important;
  }
}

.ant-upload {
  padding: 20px;

  .error {
    left: 0;
  }
}

iframe#webpack-dev-server-client-overlay {
  // remove this later
  display: none;
}

:where(.css-dev-only-do-not-override-1kuana8).ant-dropdown
  .ant-dropdown-menu
  .ant-dropdown-menu-item:hover {
  background-color: transparent !important;
}

.disableColor {
  color: #006772 !important;
}

.no-pointer {
  opacity: 0.5;
  cursor: not-allowed !important;
}

.ant-select-show-search:where(
    .css-dev-only-do-not-override-1kuana8
  ).ant-select:not(.ant-select-customize-input)
  .ant-select-selector {
  cursor: pointer;
}

.ant-pagination-options {
  .ant-select-selector {
    background: transparent !important;

    .ant-select-selection-item {
      color: #fff;
    }
  }
}

.evidenceModal {
  width: 631px !important;

  .ant-modal-body {
    gap: 20px;
    margin-top: 30px;

    img {
      margin-top: 20px;
      width: 100%;
      height: 200px;
    }
  }
}

.hide-upload div.ant-upload-select {
  display: none !important;
}

.ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 54px;
  display: inline-block;
}

div.ant-picker.ant-picker-disabled {
  div.ant-picker-input input#closureTime {
    cursor: initial !important;
    color: #fff !important;
  }

  cursor: not-allowed !important;

  &:hover {
    background-color: var(--inputColor) !important;
  }
}

.commonTextArea {
  .count {
    color: #868686;
    font-size: 16px;
    display: flex;
    justify-content: flex-end;
  }
}

.raiseDispute .disputeLeft .marketTd.fix_width {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  margin-right: 10px;
}

.profileTab .ant-table-row.ant-table-row-level-0 {
  cursor: pointer;
}

.ant-tooltip-inner {
  background-color: var(--black) !important;
}

.recentActivity .slick-slider {
  .slick-slide {
    filter: blur(4px);
  }

  .slick-active {
    filter: blur(0px);
  }

  .slick-current {
    filter: blur(0px);
  }

  .slick-cloned {
    filter: blur(0px);
  }
}

.aboutMarket .rightSec .betSlipCard .noDisable {
  background-color: transparent !important;

  &:hover {
    background-color: transparent !important;
  }
}

.aboutMarket .rightSec .betSlipCard .noBtn.noDisable {
  color: var(--error) !important;
  // svg {
  //   path {
  //     fill: var(--error) !important;
  //   }
  // }
}

.ant-spin-spinning {
  width: 70%;
}

.user-id {
  margin-top: 30px;
}

.ant-spin {
  display: flex !important;
  align-items: center;
  justify-content: center;
  max-height: 1166px !important;
}

#targetDate {
  caret-color: transparent !important;
}

// .claimModal {
.claimModal {
  .ant-modal-content {
    background-color: var(--bgcliam);
  }

  .ant-modal-header {
    background-color: transparent;
    padding-bottom: 26px;

    .ant-modal-title {
      color: var(--white);
      font-weight: 600;
      font-size: var(--headingFive);
      width: fit-content;
      border-radius: 10px;
      padding: 5px 20px;
    }
  }

  &_description {
    &_header {
      background-color: var(--black031B30);
      display: flex;
      padding: 15px 20px;
      border-radius: 10px 10px 0 0;

      p {
        font-size: var(--largeParaGraph16);
        font-weight: 500;
        flex: 1;
        color: var(--white);

        &:nth-child(2) {
          padding-left: 69px;
        }
      }
    }

    &_content {
      border: 1px solid #2e5a6d;
      border-top: 0;
      border-radius: 0 0 10px 10px;

      div {
        display: flex;
        flex: 1;
        padding: 15px 20px;

        &:first-child {
          border-bottom: 1px solid #2e5a6d;
        }

        p {
          font-size: var(--largeParaGraph16);
          font-weight: 500;
          color: var(--white);
          flex: 1;

          &:nth-child(2) {
            padding-left: 69px;
          }
        }
      }
    }

    &_ended {
      // background-color: red;
      h3 {
        font-weight: 600;
        padding-top: 20px;
        text-align: center;
        color: var(--white);
        font-size: var(--largeParaGraph);

        span {
          color: #2ccc78;
        }
      }

      .descriptionclaim {
        display: flex;
        gap: 12px;

        .yesnoBtn {
          max-width: 150px;
          width: 100% !important;
          height: 70px !important;
          padding: 0 !important;
          border-radius: 10px;
          margin-top: 15px;

          .sourceTruth {
            max-width: 150px !important;
            padding: 0 !important;
            margin-top: 0 !important;
            min-width: unset !important;
          }
        }

        &_inner {
          background: linear-gradient(2deg, #172532 2.35%, #253b4f 93.01%);
          width: 100%;
          flex: 1;
          border-radius: 10px;
          padding: 12px;
          text-align: center;
          min-height: 71px;
          margin-top: 20px;

          p {
            color: #abb9c4;
            padding-bottom: 8px;
            font-size: var(--mediumParaGraph);
          }

          h4 {
            color: var(--white);
            font-weight: 600;
            font-size: var(--largeParaGraph);
          }
        }
      }
    }

    &_button {
      padding-top: 40px;
    }
  }

  .ant-modal-close {
    color: #d1d0d0;
  }
}

// .ant-modal-root .ant-modal-mask {
//   backdrop-filter: blur(10px);
//   -webkit-filter: blur(10px);
//   background: rgba(255, 255, 255, 0.4);
// }

.ant-skeleton {
  padding: 0 20px;
}

.ant-skeleton.ant-skeleton-active .ant-skeleton-title {
  background: linear-gradient(
    90deg,
    rgba(0, 203, 223, 0.06) 25%,
    rgba(0, 0, 0, 0.15) 37%,
    rgba(0, 203, 223, 0.06) 63%
  );
  background-size: auto;
  background-size: 400% 100%;
  animation-name: ant-skeleton-loading;
  animation-duration: 1.4s;
  animation-timing-function: ease;
  animation-iteration-count: infinite;
}

.ant-skeleton.ant-skeleton-active .ant-skeleton-paragraph > li {
  background: linear-gradient(
    90deg,
    rgba(0, 203, 223, 0.06) 25%,
    rgba(0, 0, 0, 0.15) 37%,
    rgba(0, 203, 223, 0.06) 63%
  );
  background-size: auto;
  background-size: 400% 100%;
  animation-name: ant-skeleton-loading;
  animation-duration: 1.4s;
  animation-timing-function: ease;
  animation-iteration-count: infinite;
}

@keyframes ant-skeleton-loading {
  0% {
    background-position: 100% 50%;
  }

  100% {
    background-position: 0 50%;
  }
}

.ant-pagination {
  .ant-pagination-item {
    background-color: transparent;
  }
}

.ant-skeleton-button {
  min-width: 173px !important;
  height: 50px !important;
  line-height: 20px !important;
  border-radius: 11px !important;
}

.input-diabled {
  .innerTxt {
    opacity: 0.5;
  }

  input {
    opacity: 0.5 !important;
    background: var(--inputColor) !important;
  }
}

.ant-input-disabled {
  background: #222529 !important;
  opacity: 0.2;
}

.cmnSelect {
  .ant-select-disabled {
    background: #222529;
    border: unset;
    color: var(--white);
    font-size: var(--largeParaGraph16);
    border-radius: 10px;
    outline: unset;
    opacity: 50%;
    cursor: not-allowed;

    svg {
      opacity: 0.2;
      cursor: not-allowed;
    }
  }
}

.ant-skeleton .ant-skeleton-header .ant-skeleton-avatar-lg {
  width: 100px !important;
  height: 100px !important;
}

.cardSkeleton {
  // max-width: 521px;
  width: 100%;
  padding: 10px 0;
  border-radius: 20px;
  border: 1px solid var(--bgcliam);
}

.activitySelect {
  width: 170px;
}

.errorRaiseDispute {
  margin-top: 20px;
}

//
.recentNodata {
  width: 100%;

  .no-events-found {
    border-radius: 10px;
    height: unset !important;
    min-height: 200px;
    border: 0 !important;
    padding: 20px 10px !important;
    border: 0;

    .ant-skeleton {
      max-width: 521px !important;
      width: 100% !important;
      padding: 10px;
      margin-right: 10px;

      &:last-child {
        margin-right: 0;
      }

      .ant-skeleton-content {
        max-width: 521px !important;
        width: 100% !important;
      }
    }
  }

  .blue-border {
    border: 1px solid #0cc2ff33 !important;
    border-radius: 20px;
  }
}

// .recentSkeleton {
//   display: flex;
//   flex-direction: column;
//   gap: 15px;
//   width: 100%;
//   margin-right: 10px;
// }
.claimTable {
  .ant-skeleton {
    width: 100% !important;
    border-radius: 15px !important;
    overflow: hidden !important;
    padding: 0 !important;
  }

  .ant-skeleton-input {
    width: 100% !important;
    min-width: unset;
    height: 118px !important;
  }
}

.betSlipSkeleton {
  .ant-skeleton {
    width: 100% !important;
    padding: 0;
    height: 55px;
    border-radius: 12px;

    .ant-skeleton-input {
      width: 100% !important;
      padding: 0;
      height: 55px;
      border-radius: 12px;
    }
  }
}

.formBetslip {
  .ant-skeleton {
    width: 100% !important;
    padding: 0;
    height: 55px;
    border-radius: 12px;

    .ant-skeleton-input {
      width: 100% !important;
      padding: 0;
      height: 55px;
      border-radius: 12px;
    }
  }

  .secondBetBtn {
    margin: 9px 0 20px 0 !important;

    .ant-skeleton-input {
      width: 100% !important;
      padding: 0;
      height: 50px;
      border-radius: 12px;
    }
  }
}

.p-colon {
  margin-left: -4px;
}

.ant-pagination-item-ellipsis {
  color: var(--white) !important;
  top: 6px !important;
}

.ant-pagination-item-link-icon {
  color: var(--white) !important;
  margin-top: 10px;
}

.recentSkeleton {
  .aboutCardSkelton {
    .ant-skeleton-input {
      height: 138px;
      width: 100%;
      border-radius: 20px;
    }
  }
}

div.title {
  background-color: var(--bgMain);
  display: inline-flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  gap: 10px;

  @media (max-width: 991px) {
    justify-content: center;
    flex-direction: column;
  }

  h3 {
    color: var(--white);
    font-size: var(--largeParaGraph);
    font-weight: 700;
    margin-right: 10px;
    white-space: nowrap;
  }

  span.hrLine {
    margin-left: 10px;
    position: relative;
    display: inline-block;
    width: 80%;

    &::after {
      position: absolute;
      content: "";
      background-image: linear-gradient(
        90deg,
        rgba(12, 194, 255, 0.5) 0%,
        rgba(1, 11, 26, 0) 30%
      );
      width: 100%;
      height: 1px;
      top: 50%;
    }
  }
}

.topEvent-header {
  position: relative;

  &::after {
    position: absolute;
    content: "";
    background-image: linear-gradient(
      90deg,
      rgba(1, 11, 26, 0) 10%,
      rgba(255, 255, 255, 0.3),
      rgba(1, 11, 26, 0) 90%
    );
    width: 100%;
    height: 1px;
  }
}

.mb30 {
  margin-bottom: 30px;
}

.ant-pagination {
  .ant-select-outlined:not(.ant-select-disabled):not(
      .ant-select-customize-input
    ):not(.ant-pagination-size-changer):hover
    .ant-select-selector,
  .ant-select-outlined:not(.ant-select-customize-input) .ant-select-selector {
    border-color: var(--darkPurple);
  }
}

.ant-upload-wrapper.ant-upload-picture-card-wrapper
  .ant-upload.ant-upload-select:not(.ant-upload-disabled):hover,
.ant-upload-wrapper.ant-upload-picture-circle-wrapper
  .ant-upload.ant-upload-select:not(.ant-upload-disabled):hover {
  border-color: var(--darkPurple);
}

.ant-select {
  &.icon_overlapIssue {
    .ant-select-selector {
      .cardCustom {
        justify-content: center;
      }
    }
    .ant-select-selection-placeholder {
      padding: 34px;
      font-size: 17px;
      color: #ffffff !important;
      font-weight: 500;
    }
  }
}

.border50 {
  border-radius: 50%;
}

.ant-modal-wrap {
  backdrop-filter: blur(5px) !important;
  background-color: rgba(255, 255, 255, 0.3);
}

.antd-pagination-no-search::where(
    .css-dev-only-do-not-override-1gwfwyx
  ).ant-select-single.ant-select-sm {
  height: 31px !important;
}

.antd-pagination-no-search .ant-select-selector {
  border-radius: 6px !important;
}
.innerSec.upload-box .ant-upload-list-item.ant-upload-list-item-error {
  border-color: var(--darkPurple) !important;
}
:where(
    .css-dev-only-do-not-override-1hpnbz2
  ).ant-select-single.ant-select-sm.ant-select-open
  .ant-select-selection-item,
.addressLabel {
  color: #fff;
}
.addressLabel {
  margin-right: 10px;
}
.carousel .slide,
.footerBar_socialLinks .links {
  cursor: pointer;
}
.createMarket .cmnSelect .ant-select-disabled {
  border-radius: 30px;
}

.recentActivity .recentHeading h4 span {
  margin-top: 6px !important;
}
