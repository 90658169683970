.activityTable {
  margin-bottom: 70px;
  &.show-cursor {
    tbody {
      tr {
        cursor: pointer;
      }
    }
  }
  .fix_width {
    width: 100%;
  }
  .marketTd {
    display: flex;
    align-items: center;
    gap: 10px;
    &.fix_width{
      img{
        width: 38px ;
        height: 38px ;
        border-radius: 50%;
        @media(max-width:991px){
          width: 25px ;
          height: 25px ;
        }
      }
    }
    img,
    canvas {
      width: 21px ;
      height: 21px ;
    }
  }
}

.txnHash {
  color: var(--darkPurple);
}
