.createMarket {
  max-width: 1042.813px;
  width: 100%;
  margin-top: 30px;
  margin-bottom: 200px;

  @media (max-width: 767px) {
    margin-bottom: 30px;
  }

  .marketHeading {
    h2 {
      color: var(--white);
      font-size: var(--headingOne);
      font-weight: 600;
      text-align: left;

      @media (max-width: 767px) {
        margin-bottom: 20px;
      }
    }
  }

  .innerSec {
    display: flex;
    width: 100%;
    gap: 30px;
    margin-top: 30px;
    align-items: baseline;

    @media (max-width: 767px) {
      flex-wrap: wrap;
      margin: auto;
      justify-content: center;
    }

    // .cmnSelect{
    //   max-width: 506px;
    // }
    .inputLayout,
    .cmnSelect,
    .cmnDatePicker {
      width: 100%;
    }

    .mutiInput {
      display: flex;
      gap: 20px;
      align-items: end;
      width: 100%;

      @media (max-width: 410px) {
        flex-wrap: wrap;
      }
    }

    .hoursInput {
      .innerTxt {
        transform: translateY(-50%);
        top: 50%;
      }
    }

    .singleInput {
      max-width: 505px;
      width: 100%;

      @media (max-width: 767px) {
        max-width: 100%;
      }
    }
  }

  .previewBtn {
    margin-top: 40px;
    max-width: 384px;
    width: 100%;

    @media (max-width: 767px) {
      margin: 40px auto;
    }
  }
}

.cursorNot {
  input {
    cursor: not-allowed;
  }
}

.previewBtnModal {
  margin-top: 30px;

  .innerDetail {
    border-radius: 10px;
    background: #072139;
    padding: 20px;
    display: flex;
    flex-wrap: wrap;

    .detail {
      flex: 0 49%;
      // display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 30px;
      flex-wrap: wrap;
      &:nth-child(2),
      &:nth-child(4) {
        padding-left: 40px;
      }

      @media (max-width: 475px) {
        flex: 0 100%;
        &:nth-child(2),
        &:nth-child(4) {
          padding-left: 0px;
        }
      }

      h2 {
        color: var(--whited8d8d8);
        font-size: var(--largeParaGraph14);
        font-weight: 500;
        margin-bottom: 10px;
      }

      h3 {
        color: var(--white);
        font-size: var(--largeParaGraph16);
        font-weight: 500;
        display: flex;
        align-items: center;
        text-align: left;
        max-width: 187px;
        width: 100%;
      }
    }

    .description {
      border-radius: 10px;
      background: #1e3040;
      padding: 16px 14px;

      p {
        color: var(--white);
        font-family: Montserrat;
        font-size: var(--mediumParaGraph);
        font-weight: 500;
      }
    }
  }

  .btn {
    display: flex;
    gap: 20px;
    margin-top: 40px;
  }

  .info {
    margin-top: 22px;

    p {
      color: var(--whited8d8d8);
      font-size: var(--regularParagraph);
      font-weight: 500;
      display: flex;
      align-items: center;
      gap: 5px;
      margin-top: 8px;

      &:first-child {
        margin-top: 0;
      }

      span {
        color: var(--darkPurple);
        display: flex;
      }
    }
  }
}
