.inputLayout {
  position: relative;
  .input_custom {
    border: 0;
    opacity: 1;
    width: 100%;
    height: 50px;
    box-shadow: none;
    border-radius: 30px;
    background: var(--inputColor);
    backdrop-filter: blur(7px);
    padding-left: 16px;
    color: var(--white);
    font-size: var(--largeParaGraph16);
    border: 1px solid transparent;
   
    &:focus {
      box-shadow: none;
    }

    &::placeholder {
      color: #abb9c4 !important;
    }

    &:hover,
    &:focus,
    &:focus-visible,
    &:focus-within {
      outline: none !important;
      box-shadow: none !important;
      border-color:var(--darkPurple) ;
    }

    &.innerTxtInput {
      padding-right: 60px;
    }

    &.currencyInput {
      padding-right: 60px;
      height: 40px;
      background-color: #f3f3f3;

      @media (max-width: 585px) {
        height: 35px;
      }
    }

    &.referralInput {
      padding-right: 60px;
      height: 40px;
      background-color: #fff;
      color: #80838e;
      margin-bottom: 24px;

      &::placeholder {
        color: #abb9c4 !important;
      }

      @media (max-width: 585px) {
        height: 40px;
      }
    }
  }

  .inputInnerTxt {
    padding-right: 70px;
  }

  .eyeIcon {
    input {
      background: transparent;
    }

    svg path {
      fill: #8d8d8d;
    }
  }

  .innerTxt {
    position: absolute;
    right: 16px;
    top: 71%;
    transform: translateY(-60%);
    color: var(--darkPurple);
    font-size: var(--largeParaGraph16);
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }

  .onlyBorder {
    border-radius: 30px;
    border: 1px solid transparent;
    background: var(--inputColor);
  }

  .innerTxtImg {
    position: absolute;
    right: 16px;
    top: 55%;
    color: #555863;
    display: flex;
    align-items: center;

    svg {
      margin-left: 10px;
    }
  }

  .inputsearch_ryt {
    padding-right: 45px;
    border: 0;
    background-color: #f5f5f5;
    border-radius: 24px;
  }

  .searchImg {
    position: absolute;
    left: 24px;
    top: 50%;
    transform: translateY(-50%);
    z-index: 1;
    @media (max-width: 767px) {
     top: 53%;
    }
  }

  .searchImgRight {
    position: absolute;
    right: 6px;
    top: 50%;
    transform: translateY(-50%);
    cursor: pointer;
  }

  .referralimgryt {
    position: absolute;
    right: 24px;
    top: 50%;
    transform: translateY(-50%);
    cursor: pointer;
  }

  .label {
    letter-spacing: 0px;
    color: var(--whited8d8d8);
    font-size: var(--largeParaGraph16);
    font-weight: 500;
    opacity: 1;
    width: 100%;
    text-align: left;
    display: flex;
    padding-bottom: 10px;
  }

  .ant-input-affix-wrapper {
    &:focus,
    &:hover {
      border: 1px solid  #28B0DE
 !important;
    }
  }
}

.inputLayout {
  .inputSearch {
    padding-left: 50px;
    height: 50px;
 
  }
}

.inputLayout {
  .error {
    color: #ff2a00;
    margin-top: 0.5rem;
    font-size: 0.8rem;
    font-weight: 500;
    position: absolute;

    span {
      margin-right: 0.4rem;
      position: relative;
      top: -0.18rem;
    }
  }
}

.ant-input-clear-icon {
  color: #fff !important;
}

.ant-input {
  color: #fff;
  &::placeholder {
    color: #abb9c4;
  }
  &:hover {
    color: #fff !important;
  }
}
