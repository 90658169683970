.dashLayout {
  height: 100vh;
  display: flex;
  flex-direction: column;

  &_body {
    padding: 30px;

    @media (max-width: 991px) {
      padding: 20px;
    }

    @media (max-width: 991px) {
      padding: 16px;
    }
  }

  // padding-top: 100px;
  @media (max-width: 1260px) {
    // padding-top: 70px;
  }

  .ant-layout {
    background-color: transparent;
  }
  .about-page {
    padding: 0;
  }
  .demo-logo-vertical {
    margin: 20px 10px 20px 42px;
    text-align: left;
    cursor: pointer;
    img {
      width: 168px;
    }
  }

  &_container {
    flex: 1;
    // display: flex;
    // gap: 16px;

    // margin: 0 16px;
    // @media (max-width: 991px) {
    //   margin: 0 8px;
    //   gap: 8px;
    // }
    // &_body {
    //   background-color: var(--white);
    //   border: 1px solid var(--grayE4Border);
    //   border-radius: 8px;
    //   padding: 35px 32px;
    //   flex: 1;
    //   overflow-x: hidden;
    //   height: calc(100vh - 80px);
    //   overflow-y: auto;
    //   -webkit-box-shadow: 0px 0px 22px -8px rgba(87, 0, 255, 0.13);
    //   -moz-box-shadow: 0px 0px 22px -8px rgba(87, 0, 255, 0.13);
    //   box-shadow: 0px 0px 22px -8px rgba(87, 0, 255, 0.13);
    //   @media (max-width: 991px) {
    //     padding: 25px 20px;
    //     min-height: 100%;
    //   }
    // }
  }

  .ant-layout-sider {
    height: 100vh;
    background: linear-gradient(
      0deg,
      #001b30 -33.77%,
      #000917 49.79%,
      #001b30 133.15%
    );
    min-height: 500px;
    overflow-y: auto;
  }

  .ant-layout-content {
    overflow-y: auto;
    height: 100%;
  }

  .ant-menu.ant-menu-root {
    margin-top: 20px;
  }
}

.footerBar {
  padding: 25px;
  border-top: 1px solid rgba(40, 176, 222, 0.2);
  display: flex;
  justify-content: space-between;
  align-items: center;
  p {
    font-size: var(--mediumParaGraph);
    color: var(--lightWhite);
    text-align: center;
    width: 100%;
  }

  &_socialLinks {
    display: flex;
    gap: 20px;
    align-items: center;
    @media (max-width: 767px) {
      gap: 10px;
    }
  }
}

.log_outBtn {
  background: rgba(222, 40, 40, 0.2);
  color: rgba(222, 40, 40, 1);
  border: 0;
  height: 50px;
  width: 80%;
  padding: 15px;
  border-radius: 30px;
  font-weight: 400;
  font-size: var(--largeParaGraph16);
  margin-top: auto;
  margin-left: 30px;
  margin: auto 0px 20px 30px;

  &:hover,
  &:focus-within,
  &:focus-visible,
  &:active {
    border: 1px solid rgba(222, 40, 40, 1) !important;
    background: rgba(222, 40, 40, 0.2) !important;
    // background: rgba(222, 40, 40, 0.2);
    color: rgba(222, 40, 40, 1) !important;

    span {
      color: rgba(222, 40, 40, 1) !important;
    }
  }
}

.ant-layout-sider-children {
  display: flex;
  flex-direction: column;
}
