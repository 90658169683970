.recentActivity {
  background-color: #01172a;
  border-radius: 20px;
  min-height: fit-content;
  overflow: hidden;
  padding: 15px 30px;
  border: 1px solid #0000;
  height: auto;
  background: linear-gradient(#01172a, #01172a) padding-box,
    linear-gradient(
        180deg,
        rgba(12, 194, 255, 0.2) 0%,
        rgba(1, 23, 42, 0) 47.5%,
        rgba(83, 174, 204, 0.2) 100%
      )
      border-box;

  @media (max-width: 767px) {
    margin-top: 50px;
    // padding: 40px 0;
  }

  @media (max-width: 575px) {
    padding: 15px;
  }

  .recentHeading {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 30px;
    border-width: 2px;

    position: relative;

    &::after {
      position: absolute;
      content: "";
      background-image: linear-gradient(
        90deg,
        rgba(1, 11, 26, 0) -14.71%,
        rgba(12, 194, 255, 0.5) 53.33%,
        rgba(1, 11, 26, 0) 115.47%
      );
      width: 100%;
      height: 1px;
      bottom: -2px;
    }

    h4 {
      color: var(--white);
      font-size: var(--largeParaGraph16);
      font-weight: 400;
      width: 100%;
      display: inline-flex;
      align-items: center;
      justify-content: flex-start;
      gap: 10px;
    }
  }

  .recentBtn {
    // margin-top: 85px;
    // @media (max-width: 767px) {
    //   margin-top: 50px;
    // }
    button {
      border: none !important;
      background-color: transparent !important;
      font-size: var(--mediumParaGraph);
      max-width: 108px;
      width: 100%;
      margin: 0 auto;

      &:hover {
        text-decoration: underline;
        background-color: unset !important;
        color: var(--darkPurple) !important;
        text-underline-offset: 5px;
        transition: 0.3s;
      }
    }
  }

  .recentActivitycard {
    // display: flex;
    // gap: 20px;
    // flex-direction: column;

    cursor: pointer;

    .currentPrice {
      h2 {
        .marketTd {
          display: flex;
          align-items: center;
          gap: 5px;
        }
      }

      .duration {
        color: var(--lightWhite);
        font-size: var(--mediumParaGraph);
        padding-top: 8px;
        white-space: nowrap;
        text-align: right;
        margin-left: auto;
      }

      .showtime {
        display: flex;
        flex-wrap: nowrap;
      }
    }

    .cardOuter {
      border-radius: 20px;
      padding: 20px;
      padding-top: 26px;
      padding-top: 16px;
      background-color: #041d33;
      margin-bottom: 20px;
      // @media (max-width: 1100px) {
      //   width: calc(50% - 15px);
      // }
      @media (max-width: 767px) {
        width: 100%;
      }

      .topCard {
        .currentPrice {
          .inerDetail {
            display: flex;
            align-items: center;
            justify-content: flex-start;
            gap: 10px;
            margin-bottom: 15px;
            p {
              white-space: nowrap;
              color: var(--darkPurple);
              font-weight: 600;
            }
          }
        }

        .imageData {
          img {
            width: 50px;
            height: 50px;
            border-radius: 50%;

            @media (max-width: 767px) {
              width: 45px;
              height: 45px;
            }
          }
        }

        h4 {
          color: var(--white);
          font-size: 16px;
          font-weight: 500;
          text-align: left;

          @media (max-width: 1200px) {
            font-size: 14px;
          }
        }

        h2 {
          color: var(--grayDarkABB9C4);
          font-size: var(--smallParagraph);
          font-weight: 500;
          text-align: left;
          display: flex;
          align-items: center;
          gap: 5px;
          padding-top: 9px;
          justify-content: space-between;

          span {
            gap: 4px;
            display: flex;
          }

          @media (max-width: 575px) {
            flex-wrap: wrap;
          }

          .bidDecision {
            color: var(--success);
          }

          .priceVal {
            color: var(--darkPurple);
            white-space: nowrap;
          }

          .withdrew {
            color: #f3ba2f;
          }
        }
      }
    }
  }

  .recentSkeleton {
    margin-bottom: 20px;
  }

  .no-events-found {
    min-height: 65vh;
  }
}

.withdraw {
  color: #f3ba2f;
}
