.homeSec {
  .outerArea {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 24px;
    @media (max-width: 991px) {
      flex-direction: column;
      justify-content: center;
    }
    .bannerEvent {
      flex: 1 1 auto;
      @media (max-width: 991px) {
        flex: 1 1 auto;
        width: 100%;
      }
    }
    .recentActivityOuter {
      flex: 1 1 405px;
      max-width: 405px;
      min-width: 405px;
      @media (max-width: 991px) {
        flex: 1 1 100%;
        max-width: 100%;
        margin: auto;
        min-width: 100%;
      }
    }
  }
  .cardCustom.marketplace {
    .cardOuter {
      flex-basis: calc(33.3% - 12px);
      max-width: calc(33.3% - 12px);
      min-width: 300px;
      @media (max-width: 1699px) {
        min-width: 370px;
      }
      @media (max-width: 991px) {
        min-width: 300px;
      }
      @media (max-width: 479px) {
        min-width: 300px;
        max-width: 100%;
        flex-basis: 100%;
      }
    }
  }
  .cardSkeleton_outer {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-wrap: wrap;
    gap: 24px;
    .cardSkeleton {
      flex-basis: calc(33.3% - 24px);
      max-width: calc(33.3% - 24px);
      min-width: 300px;
      @media (max-width: 1699px) {
        min-width: 370px;
      }

      @media (max-width: 991px) {
        min-width: 300px;
      }
      @media (max-width: 479px) {
        min-width: 300px;
        max-width: 100%;
        flex-basis: 100%;
      }
    }
  }
}

.slick-dots {
  bottom: 6px !important;
  li {
    width: 8px !important;
  }
  button {
    width: 8px !important;
    height: 8px !important;
    border-radius: 8px !important;
  }
  .slick-active {
    width: 8px !important;
    button {
      background-color: var(--darkPurple) !important;
    }
  }
}

.banner_outer {
  max-width: 100%;
  border-radius: 20px;
  position: relative;
  overflow: hidden;

  &_Content {
    max-width: 571px;
    text-align: left;
    padding: 43px;
    .ant-btn {
      border-radius: 10px !important;
      width: 160px;
      height: 40px;
    }

    h2 {
      color: var(--white);
      margin-bottom: 20px;
      font-size: 35px;
      font-weight: 500;
      span {
        display: grid;
      }

      @media (max-width: 991px) {
        margin-bottom: 16px;
      }
      @media (max-width: 479px) {
        margin-bottom: 14px;
      }

      span.Green {
        color: var(--success);
      }
      span.Red {
        color: var(--error);
      }
    }
    p {
      color: var(--lightWhite);
      font-size: var(--mediumParaGraph);
    }
  }
}
.carousel-status,
.thumbs-wrapper {
  display: none;
}
