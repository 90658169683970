.cardCustom {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 17px;
  flex-wrap: wrap;

  @media (max-width: 1100px) {
    gap: 15px;
  }

  @media (max-width: 991px) {
    //  justify-content: center;
  }

  .cardOuter {
    border-radius: 32px;
    background-repeat: no-repeat;
    background-position: right center;
    background-size: cover;
    padding: 20px;
    min-width: 300px;
    width: 100%;
    box-sizing: border-box;
    padding-top: 16px;

    &:nth-child(odd) {
      background-image: url("../../../assets/brown_shadow_bg.svg");
    }

    &:nth-child(even) {
      background-image: url("../../../assets/blue_shadow_bg.svg");
    }

    // @media (max-width: 991px) {
    //   width: 100%;
    //   max-width: 100%;
    // }
    &_img {
      max-width: 38px;
      // margin: 15px 0;
      border-radius: 50%;
      cursor: default;

      @media (max-width: 767px) {
        max-width: 35px;
      }
    }

    .topEvent-content {
      text-align: right;
      margin-bottom: 20px;

      * {
        strong {
          font-weight: 700;
          font-size: var(--largeParaGraph16);
        }
      }

      small {
        color: var(--lightWhite);
      }
    }

    .topBar {
      display: inline-flex;
      align-items: center;
      justify-content: flex-start;
      gap: 5px;

      strong {
        font-weight: 500;
        font-size: var(--largeParaGraph16);
        color: var(--white);
      }
    }

    .topCard {
      display: flex;
      align-items: center;
      gap: 14px;
      margin-bottom: 24px;

      @media (max-width: 1100px) {
        gap: 10px;
      }

      .currentPrice {
        text-align: left;

        h4 {
          color: var(--white);
          font-size: var(--mediumParaGraph);
          font-weight: 600;
          text-transform: capitalize;

          span {
            color: var(--grayDarkABB9C4);
          }
        }

        h2 {
          color: var(--lightWhite);
          font-size: var(--mediumParaGraph);
          font-weight: 400;
          padding-top: 20px;
          line-height: 25px;
        }
      }
    }

    .cardBtn {
      display: flex;
      gap: 16px;

      .yesBtn {
        border-radius: 26px;
        border: 1px solid var(--success);
        background: transparent;
        color: var(--success);
        font-size: var(--largeParaGraph16);
        font-weight: 600;
        width: 100%;
        height: 50px;
        display: flex;
        gap: 10px;
        align-items: center;
        justify-content: center;

        &:hover {
          background: var(--success) !important;
          color: var(--mutedBlack) !important;

          svg {
            path {
              fill: var(--mutedBlack);
            }
          }
        }

        @media (max-width: 767px) {
          height: 40px;
        }
      }

      .noBtn {
        border-radius: 25px;
        border: 1px solid var(--error);
        background: transparent;
        color: var(--error);
        font-size: var(--largeParaGraph16);
        font-weight: 600;
        width: 100%;
        height: 50px;
        display: flex;
        gap: 10px;
        align-items: center;
        justify-content: center;

        &:hover {
          background: var(--error) !important;
          color: var(--mutedBlack) !important;

          svg {
            path {
              fill: var(--mutedBlack);
            }
          }
        }

        @media (max-width: 767px) {
          height: 40px;
        }
      }
    }
  }
}

.eventView {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 22px;

  h2 {
    color: var(--white);
    font-size: var(--heading30);
    font-weight: 600;
  }

  button {
    max-width: 108px;
    width: 100%;
  }
}
