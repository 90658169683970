.topMarket {
  .marketCard {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-wrap: wrap;
    gap: 18px;
    margin: 30px 0;

    @media (max-width: 991px) {
      justify-content: center;
    }
    .cardOuter {
      display: inline-flex;
      align-items: center;
      justify-content: flex-start;
      gap: 5px;
      border: 1px solid var(--btnBorder);
      border-radius: 16px;
      padding: 14px 18px;
      cursor: pointer;
      transition: all ease-in 0.3s;
      &:hover,
      &.active {
        background: linear-gradient(
          90deg,
          rgba(1, 11, 26, 0) -14.71%,
          rgba(12, 194, 255, 0.2) 53.33%,
          rgba(1, 11, 26, 0) 115.47%
        );
        border-color: var(--darkPurple);
        scale: 1.1;
        transition: all ease-out 0.3s;
      }

      @media (max-width: 991px) {
        padding: 12px;
      }
      img {
        cursor: pointer;
        width: 32px;
        height: 32px;
        @media (max-width: 575px) {
          width: 25px;
          height: 25px;
        }
      }
      p {
        color: var(--white);
        font-weight: 400;
        font-size: var(--mediumParaGraph);
        transition: 0.3s;
        line-height: 30px;
        &:hover {
          font-family: unset !important;
        }
      }
    }
  }
}
