.about {
  color: var(--white);

  h5 {
    font-size: var(--headingThree);
    font-weight: 800;
  }

  h3 {
    font-size: var(--heading40);
    font-weight: 800;
  }

  p {
    font-size: var(--largeParaGraph16);
    font-weight: 400;
    color: var(--grayDarkABB9C4);
    line-height: 23px;

    @media (max-width: 676px) {
      line-height: 22px;
    }
  }

  &_contanier {
    padding: 30px;

    @media (max-width: 767px) {
      padding: 30px 15px;
    }
  }

  &_banner {
    background-image: url(../../assets/aboutBanner.svg);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    width: 100%;
    min-height: 387px;
    border-radius: 15px;
    padding: 40px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-top: 70px;

    @media (max-width: 767px) {
      height: 200px;
    }

    &_inner {
      max-width: 634px;
      width: 100%;

      .btnCustom {
        max-width: 187px !important;
        width: 100% !important;
        margin-top: 40px !important;

        &:hover {
          background: #02334c !important;
          color: var(--white) !important;
        }
      }
    }

    h1 {
      font-size: var(--heading40);
      color: var(--headerBg);
      margin-bottom: 15px;
      font-weight: 700;
    }

    p {
      color: var(--headerBg);
    }
  }

  // &_introduction {
  //   display: flex;
  //   justify-content: space-between;
  //   gap: 40px;
  //   text-align: left;
  //   margin-top: 119px;

  //   @media (max-width: 1320px) {
  //     flex-direction: column;
  //     justify-content: center;
  //     align-items: center;
  //   }

  //   @media (max-width: 991px) {
  //     margin-top: 69px;
  //   }

  //   @media (max-width: 500px) {
  //     margin-top: 45px;
  //   }

  //   p {
  //     color: var(--grayDarkABB9C4);
  //     font-size: var(--largeParaGraph16);
  //     font-weight: 500;
  //   }

  //   &_left {
  //     flex: 50%;
  //     display: flex;

  //     @media (max-width: 767px) {
  //       flex-direction: column;
  //     }

  //     &_card {
  //       background: linear-gradient(2deg, #172532 2.35%, #253b4f 93.01%);
  //       padding: 42px 23px;
  //       border-radius: 20px;
  //       height: 375px;
  //       max-width: 380px;
  //       width: 100%;

  //       @media (max-width: 767px) {
  //         max-width: unset;
  //         width: unset;
  //         padding: 20px;
  //         height: 100%;
  //       }

  //       &:first-child {
  //         padding-right: 78px;

  //         @media (max-width: 767px) {
  //           padding-right: 20px;
  //         }
  //       }

  //       &:last-child {
  //         border: 10px solid var(--layoutbgcolor);
  //         margin-left: -47px;
  //         margin-top: 80px;
  //         border-radius: 0 30px 30px 30px;

  //         @media (max-width: 767px) {
  //           margin-left: 0;
  //           margin-top: 15px;
  //           border: unset;
  //           border-radius: 30px;
  //         }
  //       }
  //     }
  //   }

  //   &_right {
  //     flex: 50%;
  //   }
  // }
  &_howWork {
    background: #2d303f33;
    padding: 30px;
    border-radius: 24px;

    @media (max-width: 767px) {
      padding: 30px 0;
    }
  }

  &_howWork,
  &_metrics,
  .howitwork,
  &_faq {
    margin-top: 110px;

    @media (max-width: 991px) {
      margin-top: 69px;
    }

    // @media (max-width: 500px) {
    //   margin-top: 25px;
    // }

    h3 {
      margin-bottom: 20px;
      text-align: center;

      @media (max-width: 500px) {
        margin-bottom: 8px;
      }
    }

    p {
      text-align: center;
    }

    &_cards {
      margin-top: 50px;

      @media (max-width: 500px) {
        margin-top: 25px;
      }

      .ant-row {
        display: flex;
        justify-content: flex-start;
      }
    }
  }

  &_faq {
    &_collapse {
      // max-width: 894px;
      max-width: 100%;
      width: 100%;
      margin: 0 auto;
      margin-top: 50px !important;

      .ant-collapse-content {
        color: #010516;
        font-weight: 500;
      }

      @media (max-width: 500px) {
        margin-top: 25px !important;
      }

      .ant-collapse-header {

        &-text {
          font-size: var(--headingFour);
          font-weight: 500;
          // color: #010516;
        }
      }

      .ant-collapse-item {
        border-radius: 20px !important;
      }

      .ant-collapse-item-active {
        .ant-collapse-header {
          padding-bottom: 15px !important;
        }
      }
    }
  }
}

.aboutCommonCard {
  background: linear-gradient(0deg, rgba(62, 135, 176, 0.3) 0%, rgba(10, 14, 31, 0.29) 100%);
  border-radius: 24px;
  padding: 30px;
  text-align: center;
  height: 100%;
  cursor: pointer;
  position: relative;
  &::before {
    content: "";
    position: absolute;
    top: -1px;
    left: -1px;
    width: 100%;
    height: 100%;
    background: linear-gradient(90deg,
        #193048 0%,
        #193148 101.48%);
    border-radius: 24px;
    padding: 1px;
    -webkit-mask: linear-gradient(#000 0 0) content-box,
      linear-gradient(#000 0 0);
    mask: linear-gradient(#000 0 0) content-box, linear-gradient(#000 0 0);
    -webkit-mask-composite: destination-out;
    mask-composite: exclude;
    z-index: 0;
  }

  @media (max-width: 767px) {
    padding: 11px 15px;
    // height: auto;
  }

  h5 {
    margin: 25px 0 10px;
    font-size: var(--headingFour);
    font-weight: 700;
    line-height: 24px;
  }

  p {
    color: var(--grayDarkABB9C4);
    font-size: var(--largeParaGraph16);
    font-weight: 400;
    line-height: 24px;

    @media (max-width: 767px) {
      line-height: 22px;
    }
  }

  &:hover {
    background: linear-gradient(89.02deg, #3E87B0 1.19%, #A4C0CF 99.34%);
    color: var(--black);

    rect {
      fill: var(--black);
      fill-opacity: 1;
    }

    g {
      opacity: 1;
    }

    path {
      fill: var(--white);
    }

    p {
      color: #111b24;
    }
  }
}

.metricsCard {
  background: linear-gradient(0deg, rgba(62, 135, 176, 0.3) 0%, rgba(10, 14, 31, 0.29) 100%);
  border-radius: 24px;
  // padding: 30px;

  // background: linear-gradient(0deg, rgba(62, 135, 176, 0.3) 0%, rgba(10, 14, 31, 0.29) 100%);
  // border: 1px solid #ffffff1a;
  // border-radius: 20px;
  padding: 42px 10px;
  text-align: center;
  position: relative;
  &::before {
    content: "";
    position: absolute;
    top: -1px;
    left: -1px;
    width: 100%;
    height: 100%;
    background: linear-gradient(90deg,
        #193048 0%,
        #193148 101.48%);
    border-radius: 24px;
    padding: 1px;
    -webkit-mask: linear-gradient(#000 0 0) content-box,
      linear-gradient(#000 0 0);
    mask: linear-gradient(#000 0 0) content-box, linear-gradient(#000 0 0);
    -webkit-mask-composite: destination-out;
    mask-composite: exclude;
    z-index: 0;
  }

  // border-radius: 20px;
}

////////////// New Design Styles //////////////////////////

.aboutCommonHead {
  color: var(--white);
  font-size: var(--heading40);
  font-weight: 700;
  margin-bottom: 30px;
}

.introSec {
  max-width: 1204px;
  width: 100%;
  margin: 132px auto;

  @media (max-width: 1366px) {
    margin: 80px auto;
  }

  &_top {
    text-align: center;
  }

  &_bottom {
    display: flex;
    gap: 30px;
    margin-top: 40px;
    flex-flow: wrap;

    @media (max-width:767px) {
      flex-direction: column;
    }

    &_card {
      flex: 1;
      // background-image: url(../../assets/IntroCardBg.png);
      // background: linear-gradient(360deg, rgba(16, 16, 21, 0) 0%, #1F2843 100%);
      // border: 1px solid #2B2F52;
      border: 1px solid #2B2F52;
      background: linear-gradient(360deg, rgba(16, 16, 21, 0) 0%, #1F2843 100%);
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center;
      width: 100%;
      min-height: 362px;
      padding: 30px;
      padding-top: 17px;
      border-radius: 24px;
      position: relative;

      &::after {
        content: '';
        opacity: 0.7;
        background-image: url('../../assets/CardBgGred.png');
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
        position: absolute;
        right: 0;
        top: 0;
        width: 100%;
        height: 100%;
        border-radius: 20px;
      }

      h5 {
        font-size: var(--headingFour);
        font-weight: 700;
        margin-bottom: 15px;
      }

      &_img {
        text-align: end;

        img {
          max-width: 174px;
          width: 100%;
        }
      }
    }
  }
}

.howitwork {
  &_card {
    text-align: left !important;
    max-width: 380px;
    width: 100%;
    padding: 30px !important;

    @media (max-width: 1600px) {
      max-width: 300px;
    }

    @media (max-width: 767px) {
      max-width: 100%;
    }

    &_count {
      padding: 18px;
      max-width: 80px;
      width: 100%;
      height: 80px;
      background: linear-gradient(89.02deg,
          rgba(62, 135, 176, 0.5) 1.19%,
          rgba(164, 192, 207, 0.5) 99.34%);
      font-size: var(--heading40);
      font-weight: 700;
      border-radius: 12px;
      margin-bottom: 27px;
    }

    p {
      text-align: left !important;
      line-height: 24px;
      margin-top: 17px;
    }
  }

  &_inner {
    display: flex;
    gap: 40px;
    // justify-content: space-between;
    justify-content: center;
    align-items: center;
    margin-top: 73px;
    flex-flow: wrap;

    @media (max-width: 991px) {
      flex-direction: column-reverse;
    }

    @media (max-width: 767px) {
      max-width: 100%;
    }

    &_left {

      display: flex;
      flex-flow: wrap;
      gap: 20px;
      max-width: 780px;
      width: 100%;

      @media (max-width: 1600px) {
        max-width: 620px;
      }
    }

    &_right {
      flex: 1;
      text-align: center;

      img {
        max-width: 569px;
        width: 100%;
        margin: auto;

        @media (max-width: 767px) {
          max-width: 400px;
        }
      }
    }
  }
}

.roadmap {
  background: #2d303f33;
  padding: 30px;
  border-radius: 24px;
  margin-top: 130px;

  @media (max-width: 991px) {
    margin-top: 69px;
  }

  // @media (max-width: 767px) {
  //   padding: 0;
  // }

  &_steps {
    display: flex;
    align-items: center;

    img {
      max-width: 984px;
      width: 100%;
      margin: auto;

      @media (max-width: 1600px) {
        max-width: 700px;
      }
    }
  }

  &_bottom {
    display: flex;
    gap: 50px;
    justify-content: space-between;
    margin: 40px 90px 0 90px;

    // flex-flow: wrap;
    @media (max-width: 1600px) {
      margin: 40px 30px 0 30px;
    }

    @media (max-width: 1366px) {
      margin: 40px 0 0 0;
    }

    @media (max-width: 991px) {
      flex-direction: column;
      padding-left: 50px;
      position: relative;

      &::before {
        content: "";
        position: absolute;
        left: 0;
        top: 120px;
        border-left: 4px solid #9FBDCE;
        height: 74%;

        @media (max-width: 767px) {
          top: 50%;
          transform: translateY(-50%);
        }
      }
    }
  }

  &_cardSec {
    max-width: 425px;
    width: 100%;
    display: flex;
    flex-direction: column;

    @media (max-width: 991px) {
      max-width: 100%;
    }

    @media (max-width: 767px) {
      &:first-child {
        .roadmapCard {
          &::before {
            top: 35%;
            transform: translateY(-50%);
          }
        }
      }

      &:last-child {
        .roadmapCard {
          &::before {
            top: 55%;
            transform: translateY(-50%);
          }
        }
      }
    }
  }

  h4 {
    text-align: center;
    font-size: var(--heading30);
    font-weight: 700;
    margin-bottom: 20px;
  }

  h3 {
    text-align: center;
    margin-bottom: 40px;
  }
}

.roadmapCard {
  padding: 30px;
  border-radius: 20px;
  background: linear-gradient(89.02deg, #3e87b0 1.19%, #a4c0cf 99.34%);
  flex: 1;



  @media (max-width: 991px) {
    position: relative;

    &::before {
      content: "";
      position: absolute;
      left: -65px;
      top: 50%;
      transform: translateY(-50%);
      background-image: url('../../assets/Circle.png');
      background-position: center;
      background-repeat: no-repeat;
      background-size: contain;
      z-index: 1;
      width: 34px;
      height: 34px;

    }
  }

  h5 {
    color: var(--headerBg);
    font-size: var(--headingFour);
    font-weight: 700;
  }

  ul {
    padding-left: 14px;

    li {
      font-size: var(--largeParaGraph16);
      font-weight: 400;
      color: var(--headerBg);
      line-height: 23px;

      @media (max-width: 676px) {
        line-height: 22px;
      }
    }
  }
}

.aboutBanner {
  padding: 0;
  background-image: url(../../assets/AboutUsBanner.png);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  width: 100%;
  min-height: 1040px;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 167px;

  @media (max-width:767px) {
    min-height: auto;
    margin-top: 50px;
    padding: 0 15px;
  }

  &_top {
    text-align: center;

    @media (max-width:767px) {
      img {
        max-width: 400px;
        width: 100%;
      }
    }

    h2 {
      color: #a8c3dd;
      font-size: var(--heading48);
      margin-bottom: 27px;
    }

    p {
      margin-top: 35px;
    }
  }

  &_bottom {
    img {
      max-width: 916px;
      width: 100%;
    }
  }
}